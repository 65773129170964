<template>
    <div
        class="scs-lierue-portal-container"
        :class="'scs-' + scsName">
        <router-view />
    </div>
</template>

<script>
    import axios from 'axios'
    import Vue from "vue";
    import { localeChanged } from 'vee-validate';
    export default {
        data () {
            return {
                scsName: 'lierue-frontend-portal',
                eventBus: new Vue()
            }
        },
        methods: {
            checkToken() {
                if (window.keycloak && window.keycloak.token) {
                    this.$store.dispatch('userModule/onValidTokenExists', window.keycloak)
                    return true
                }
                return false
            },
            checkTokenDelayed(timeout = 100) {
                setTimeout(() => {
                    if (!this.checkToken() && timeout * 2 <= 30000) {
                        this.checkTokenDelayed(timeout * 2)
                    }
                }, timeout)
            },
            setLanguage () {
                let clientLanguage = 'de_DE'

                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length && /^[a-zA-Z_]{2,5}$/.test(langCode)) {
                        clientLanguage = langCode
                    }
                }

                this.$i18n.locale = clientLanguage
                localeChanged()
            }
        },
        provide: function () {
            return {
                editEventBus: this.eventBus
            }
        },
        created () {
            /**
             * Don't remove any existing code here (unless you're 100% sure what you're doing)!
             */
            axios.interceptors.request.use(
                async (config) => {
                    if (typeof window === 'object' && window !== null) {
                        if (window.keycloak && window.keycloak.token) {
                            let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                            if (tokenExpires < 10) {
                                console.log('[axios] Token expires soon. Updating token before sending request')
                                await window.updateKeycloakToken()
                            } else {
                                console.log('[axios] Token still valid. No need to refresh before sending request')
                            }
                            config.headers.Authorization = 'Bearer ' + window.keycloak.token
                        }
                    }

                    if (sessionStorage.getItem('actForMemberId') !== null) {
                        config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                    } else {
                        delete config.headers['X-Act-For-Member-Id']
                    }

                    return config
                },
                (error) => Promise.reject(error)
            )

            if (!this.checkToken()) {
                this.checkTokenDelayed()
            }

            window.addEventListener('loggedIn', () => {
                this.checkToken()
            }, false)

            window.addEventListener('keycloakTokenUpdated', () => {
                this.$store.dispatch('userModule/onKeycloakTokenUpdateSuccess', window.keycloak)
            }, false)

            if (process && process.env) {
                if (typeof process.env.PACKAGE_NAME === 'string' && process.env.PACKAGE_NAME.includes('/')) {
                    this.scsName = process.env.PACKAGE_NAME.split('/')[1]
                }
                if (process.env.NODE_ENV !== 'test') {
                    console.log(`SCS ${process.env.PACKAGE_NAME} ${process.env.PACKAGE_VERSION}`)
                }
            }
        },
        mounted() {
            window.addEventListener('languageChanged', this.setLanguage, false)
            this.setLanguage()
        },
        beforeDestroy () {
            window.removeEventListener('languageChanged', this.setLanguage)
        }
    }
</script>

<style scoped lang="less">
    .scs-lierue-portal-container {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 195px);
    }
</style>
