<template>
    <div class="row lierue-form-step">
        <div class="col-12 box-spacing">
            <div class="h1" data-qa="selected-supplier-inquiry--company-name">{{
                    supplierinquiry.businessName
                }}
            </div>
            <div v-show="showForm">
                <ValidationObserver v-slot="{ invalid, errors }">
                    <div class="crefo-input crefo-ui-select">
                        <div class="input-group" data-qa="supplierInquiry-businessRelationDurationInYears">
                            <label
                                for="supplierInquiry-businessRelationDurationInYears">{{
                                    $t('Supplierinquiry.Label.DauerGeschaeftsverbindung')
                                }}</label>
                            <div class="select-group">
                                <div class="input-wrapper">
                                    <select id="supplierInquiry-businessRelationDurationInYears"
                                            v-model="dauerGeschaeftsverbindung" data-qa="comboBox-container">
                                        <option value='' data-qa="comboBox-list-item">{{ $t("Supplierinquiry.Key.PleaseChoose") }}</option>
                                        <option value=0 data-qa="comboBox-list-item">{{$t("Supplierinquiry.Key.BusinessRelationDurationInYears.0")}}</option>
                                        <option v-for="i in 50" data-qa="comboBox-list-item">{{ i }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input-field type="text"
                                 field="supplierInquiry-turnoverAmount"
                                 :label="$t('Supplierinquiry.Label.Jahresumsatz')"
                                 unit="€"
                                 :placeholder=amountPlaceholder
                                 lazy
                                 :readonly=hasBusinessRelation
                                 :class="{ 'lierue-input--disabled': hasBusinessRelation}"
                                 v-model="umsatz"/>
                    <div class="crefo-input crefo-ui-select">
                        <div class="input-group" data-qa="supplierInquiry-turnoverYear">
                            <label for="supplierInquiry-turnoverYear">{{ $t('Supplierinquiry.Label.Jahr') }}</label>
                            <div class="select-group" :class="{ 'lierue-select--disabled': hasBusinessRelation}">
                                <div class="input-wrapper">
                                    <select id="supplierInquiry-turnoverYear" v-model="jahr"
                                            data-qa="comboBox-container">
                                        <option value='' data-qa="comboBox-list-item">Bitte wählen</option>
                                        <option v-for="i in 50" data-qa="comboBox-list-item">{{
                                                currentYear + 1 - i
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="crefo-input crefo-ui-select">
                        <div class="input-group" data-qa="supplierInquiry-paymentMode">
                            <label for="supplierInquiry-paymentMode">{{
                                    $t('Supplierinquiry.Label.Zahlungsweise')
                                }}</label>
                            <div class="select-group" :class="{ 'lierue-select--disabled': hasBusinessRelation}">
                                <div class="input-wrapper">
                                    <select id="supplierInquiry-paymentMode" v-model="zahlungsweise">
                                        <option value='' data-qa="comboBox-list-item">
                                            {{ $t("Supplierinquiry.Key.PleaseChoose") }}
                                        </option>
                                        <option v-for="paymentMode in paymentModes" :value="paymentMode[0]"
                                                data-qa="comboBox-list-item">
                                            {{ paymentMode[1] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="zahlungsweise==='DELAYED_PAYMENT'">
                        <div class="container supplierInquiry--details">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-5">
                                    <p>
                                        {{ $t('Supplierinquiry.Label.ZielüberschreitungUm') }}</p>
                                </div>
                                <div class="col-4 col-md-3 col-xl-3">
                                    <input-field type="text"
                                                 :rules="{ numeric: '', max_value: 9999999999}"
                                                 field="supplierInquiry-delayedPaymentInDays"
                                                 v-model="zielueberschreitungUm"/>

                                </div>
                                <div class="col-8 col-md-3 col-xl-4">
                                    <p>{{ $t('Supplierinquiry.Label.Tage') }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-5">
                                    <p>
                                        {{ $t('Supplierinquiry.Label.BeiZielvereinbarung') }}</p>
                                </div>
                                <div class="col-4 col-md-3 col-xl-3">
                                    <input-field type="text"
                                                 :rules="{ numeric: '', max_value: 9999999999}"
                                                 field="supplierInquiry-targetAgreementInDays"
                                                 v-model="beiZielvereinbarung"/>

                                </div>
                                <div class="col-8 col-md-3 col-xl-4">
                                    <p>{{ $t('Supplierinquiry.Label.Tage') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="zahlungsweise==='AFTER_DUNNING'">
                        <div class="container supplierInquiry--details">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-5">
                                    <p>
                                        {{ $t('Supplierinquiry.Label.Nach') }}</p>
                                </div>
                                <div class="col-4 col-md-3 col-xl-3">
                                    <div class="crefo-input crefo-ui-select">
                                        <div class="input-group" data-qa="supplierInquiry-dunningCount">
                                            <div class="select-group">
                                                <div class="input-wrapper">
                                                    <select id="supplierInquiry-dunningCount" v-model="mahnungen">
                                                        <option v-for="i in 4" data-qa="comboBox-list-item">{{ i }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 col-md-3 col-xl-4">
                                    <p>{{ $t('Supplierinquiry.Label.Mahnungen') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input-field type="text"
                                 field="supplierInquiry-creditLimit"
                                 :label="$t('Supplierinquiry.Label.Kreditlimit')"
                                 unit="€"
                                 :placeholder=amountPlaceholder
                                 lazy
                                 :readonly=hasBusinessRelation
                                 :class="{ 'lierue-input--disabled': hasBusinessRelation}"
                                 v-model="kreditlimit"/>

                    <div class="crefo-input">
                        <div class="input-group">
                            <label for="supplierInquiry-Kommentar">{{$t("Supplierinquiry.Label.Sonstiges")}}</label>
                            <div class="input-wrapper" :class="{ 'lierue-textarea--disabled': inputDisabled}">
                        <textarea id="supplierInquiry-Kommentar"
                                  v-model="kommentar"
                                  :readonly="inputDisabled"
                                  data-qa="supplierInquiry-remarks"
                        ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="lierue-button">
                        <input type="submit"
                               class="btn btn-default"
                               :value="$t('Supplierinquiry.Button.LierueSenden')"
                               :disabled="buttonDisabled||invalid"
                               @click.prevent="sendLierue"
                               data-qa="supplierInquiry-sendBtn"></input>
                    </div>
                    <div class="lierue-button">
                        <a href="#" class="link-icon-chevron-next" @click.prevent="ignoreLierue"
                           data-qa="supplier-inquiry-ignore-link"><span>{{
                                $t('Supplierinquiry.Button.LierueIgnorieren')
                            }}</span></a>
                    </div>
                </ValidationObserver>
            </div>
            <div class="lierue-message" :class="{ 'lierue-message--show': this.showMessage}"
                 data-qa="supplierInquiry-LierueMessage">
                <div v-if="showClose" class="crefo-ui-alert success" data-qa="supplierinquiry-successfully-saved">
                    {{ this.currentMessage }}
                    <span class="alert-close" @click.prevent="closeMessage"
                          data-qa="messageBox-confirm"/>
                </div>
                <div v-else class="crefo-ui-alert success" v-html="this.currentMessage"
                     data-qa="supplierInquiry-dataSavedSuccessfully">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import InputField from '@/components/commons/input-field'
import {mapState} from "vuex";
import {ValidationObserver} from 'vee-validate'
import i18n from "@/i18n";
import initializr from "@/mixins/initializr";
import formatter from "@/mixins/formatter";
import keys from "@/mixins/keys";

export default {
    name: "SupplierInquiriy",
    components: {ValidationObserver, InputField},
    props: {
        supplierInquiriesConfig: null,
        isInvalid: true
    },
    data() {
        return {
            currentMessage: "",
            currentYear: new Date().getFullYear(),
            showForm: true
        }
    },
    computed: {
        ...mapState('supplierinquiryModule', ['supplierinquiry', 'supplierinquiryReply', 'supplierinquiryReplyFetching', 'ajaxError']),
        dauerGeschaeftsverbindung: {
            get() {
                let result = ""
                if (this.supplierinquiryReply) {
                    if (this.supplierinquiryReply.businessRelationExists !== null) {
                        if (this.supplierinquiryReply.businessRelationDurationInYears > 0) {
                            result = this.supplierinquiryReply.businessRelationDurationInYears
                        } else {
                            result = "0"
                        }
                    }
                }
                return result
            },
            set(newValue) {
                this.initSupplierinquiryReply(this.$store.commit, this.supplierinquiry.id, this.supplierinquiry.memberUserId)
                if (newValue !== '') {
                    if (newValue === '0') {
                        this.$store.commit('supplierinquiryModule/ON_CHANGE_BUSINESSRELATION_EXISTS', false)
                    } else {
                        this.$store.commit('supplierinquiryModule/ON_CHANGE_BUSINESSRELATION_EXISTS', true)
                        this.$store.commit('supplierinquiryModule/ON_CHANGE_BUSINESS_RELATION_DURATION_IN_YEARS', this.parseNumber(newValue))
                    }
                }
            }
        },
        umsatz: {
            get() {
                if (this.supplierinquiryReply && this.supplierinquiryReply.turnoverAmount) {
                    return this.formatAmount(this.supplierinquiryReply.turnoverAmount.value, this.$i18n.locale)
                } else {
                    return ""
                }
            },
            set(newValue) {
                let payload
                if (newValue !== '') {
                    payload = {
                        value: this.parseAmount(newValue, this.$i18n.locale),
                        currency: "EUR"
                    }
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_TURNOVER_AMOUNT', payload)
            }
        },
        jahr: {
            get() {
                let result = ""
                if (this.supplierinquiryReply) {
                    result = this.supplierinquiryReply.turnoverYear
                }
                return result
            },
            set(newValue) {
                let payload
                if (newValue !== '') {
                    payload = this.parseNumber(newValue)
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_TURNOVER_YEAR', payload)
            }
        },
        zahlungsweise: {
            get() {
                let result = ""
                if (this.supplierinquiryReply && this.supplierinquiryReply.paymentMode) {
                    result = this.supplierinquiryReply.paymentMode
                }
                return result
            },
            set(newValue) {
                let payload
                this.$store.commit('supplierinquiryModule/ON_CHANGE_TARGET_AGREEMENT_IN_DAYS', null)
                this.$store.commit('supplierinquiryModule/ON_CHANGE_DELAYED_PAYMENT_IN_DAYS', null)
                this.$store.commit('supplierinquiryModule/ON_CHANGE_DUNNING_COUNT', null)
                if (newValue !== '') {
                    payload = newValue
                    if (newValue === 'AFTER_DUNNING') {
                        this.$store.commit('supplierinquiryModule/ON_CHANGE_DUNNING_COUNT', 1)
                    }
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_PAYMENT_MODE', payload)
            }
        },
        zielueberschreitungUm: {
            get() {
                let result = ""
                if (this.supplierinquiryReply && this.supplierinquiryReply.delayedPaymentInDays) {
                    result = this.supplierinquiryReply.delayedPaymentInDays
                }
                return result
            },
            set(newValue) {
                let payload
                if (newValue !== '') {
                    payload = newValue
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_DELAYED_PAYMENT_IN_DAYS', payload)
            }
        },
        beiZielvereinbarung: {
            get() {
                let result = ""
                if (this.supplierinquiryReply && this.supplierinquiryReply.targetAgreementInDays) {
                    result = this.supplierinquiryReply.targetAgreementInDays
                }
                return result
            },
            set(newValue) {
                let payload
                if (newValue !== '') {
                    payload = newValue
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_TARGET_AGREEMENT_IN_DAYS', payload)
            }
        },
        mahnungen: {
            get() {
                let result = ""
                if (this.supplierinquiryReply && this.supplierinquiryReply.dunningCount) {
                    result = this.supplierinquiryReply.dunningCount
                }
                return result
            },
            set(newValue) {
                this.$store.commit('supplierinquiryModule/ON_CHANGE_DUNNING_COUNT', this.parseNumber(newValue))
            }
        },
        kreditlimit: {
            get() {
                if (this.supplierinquiryReply && this.supplierinquiryReply.creditLimit) {
                    return this.formatAmount(this.supplierinquiryReply.creditLimit.value, this.$i18n.locale)
                } else {
                    return ""
                }

            },
            set(newValue) {
                let payload
                if (newValue !== '') {
                    payload = {
                        value: this.parseAmount(newValue, this.$i18n.locale),
                        currency: "EUR"
                    }
                } else {
                    payload = null
                }
                this.$store.commit('supplierinquiryModule/ON_CHANGE_CREDIT_LIMIT', payload)
            }
        },
        kommentar: {
            get() {
                return this.supplierinquiryReply.remarks
            },
            set(newValue) {
                this.$store.commit('supplierinquiryModule/ON_CHANGE_REMARKS', newValue)
            }
        },
        inputDisabled() {
            let result
            if (this.supplierinquiryReply.businessRelationExists !== null) {
                result = false
            } else {
                result = true
            }
            return result
        },
        hasBusinessRelation() {
            let result
            if (this.supplierinquiryReply.businessRelationDurationInYears > 0) {
                result = false
            } else {
                result = true
            }
            return result
        },
        buttonDisabled() {
            return this.supplierinquiryReply.businessRelationExists === null || this.supplierinquiryReplyFetching === true || (this.supplierinquiryReply.businessRelationDurationInYears > 0 && this.supplierinquiryReply.paymentMode === null) || this.currentMessage.length > 0
        },
        showMessage() {
            return this.currentMessage.length
        },
        showClose() {
            return this.supplierInquiriesConfig
        },
    },
    methods: {
        closeMessage() {
            this.currentMessage = ""
            if (this.supplierInquiriesConfig) {
                this.supplierInquiriesConfig.showSupplierInquiries = true
                this.editEventBus.$emit("select-supplierinquiries")
            }
        },
        async sendLierue() {
            const payload = this.supplierinquiryReply
            await this.$store.dispatch('supplierinquiryModule/supplierinquiryReply', payload)
            if (this.ajaxError === null) {
                if (this.supplierInquiriesConfig) {
                    this.currentMessage = i18n.t('SupplierinquiryReply.Success')
                } else {
                    this.currentMessage = i18n.t('SupplierinquiryReply.Success.Extended')
                    this.showForm = false
                    this.editEventBus.$emit("scroll-to-lierue-start")
                }
            } else {
                this.currentMessage = ""
                if (this.supplierInquiriesConfig) {
                    this.editEventBus.$emit("scroll-to-lierue-uebersicht-start")
                } else {
                    this.editEventBus.$emit("scroll-to-lierue-start")
                }
            }
        },
        async ignoreLierue() {
            const payload = this.supplierinquiryReply
            await this.$store.dispatch('supplierinquiryModule/deleteSupplierinquiry', payload)
            if (this.ajaxError === null) {
                this.currentMessage = i18n.t('Supplierinquiry.Success')
                if (this.supplierInquiriesConfig) {
                } else {
                    this.showForm = false
                    this.editEventBus.$emit("scroll-to-lierue-start")
                }
            } else {
                this.currentMessage = ""
                if (this.supplierInquiriesConfig) {
                    this.editEventBus.$emit("scroll-to-lierue-uebersicht-start")
                } else {
                    this.editEventBus.$emit("scroll-to-lierue-start")
                }
            }
        },
    },
    mixins: [initializr, formatter, keys],
    inject: {
        editEventBus: "editEventBus"

    }
}
</script>

<style scoped lang="less">
@ui-icon-chevron-next: "\e90a";

/deep/ a {
    &.link-icon-chevron-next {
        font-weight: 600;
        text-transform: uppercase;

        span {
            position: relative;

            &::before,
            &::after {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'crefo-ui-icons' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        padding-left: .9rem;

        span {
            margin-left: 15px;

            &:before {
                content: @ui-icon-chevron-next;
                font-size: 21px;
                padding-right: 5px;
                left: -29px;
            }
        }
    }
}

.lierue-form-step {
    min-height: 998px;

    .h1 {
        overflow: hidden;
    }

    .lierue-input, .lierue-button {
        margin: 30px 0 0;
    }

    .supplierInquiry--details {
        padding-top: 0.5rem;

        p {
            padding-top: 1rem;
        }
    }

    .lierue-button {
        display: flex;
        flex-direction: row-reverse;

        span {
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }

    .lierue-input--disabled, .lierue-textarea--disabled {
        /deep/ input, /deep/ textarea {
            cursor: not-allowed;
            background-color: rgb(237, 236, 229) !important;
        }
    }

    .lierue-select--disabled {
        cursor: not-allowed;

        .input-wrapper {
            pointer-events: none !important;
        }

        /deep/ .select-selected {
            background-color: rgb(237, 236, 229);
        }
    }

    .lierue-message {
        margin: 60px 0 0 0;
        display: none;

        &--show {
            width: 100%;
            display: inline-block;
        }

    }
}

@media only screen and (max-width: 767px) {

    .lierue-form-step {
        .lierue-button {
            [type="submit"] {
                width: 100%;
            }
        }
    }
}

/deep/ .crefo-input {
    .input-group {
        .input-wrapper {
            i, em {
                display: inline-block;
                font-style: normal;
            }

            em {
                position: absolute;
                top: 51%;
                transform: translateY(-50%);
                right: 1.2rem;
                font-size: 24px;
            }

            .left-em {
                left: 1.2rem;
            }
        }
    }
}

</style>
