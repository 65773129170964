/**
 * Should be called, when the keycloak token was initially set.
 *
 * @param {UserState} state
 * @param {Object} data
 * @param {?string} data.token
 * @param {Object} data.idTokenParsed
 * @param {?string} data.idTokenParsed.email
 * @param {string} data.idTokenParsed.given_name
 * @param {string} data.idTokenParsed.family_name
 * @param {Array<string>} data.idTokenParsed.cr_userrole
 * @param {?string} data.idTokenParsed.cr_id
 * @param {?string} data.idTokenParsed.cr_membernumber
 */

export function ON_VALID_TOKEN_EXISTS (state, data) {
    state.authenticated = true
    state.token = data.token
    state.user.email = data.idTokenParsed.email
    state.user.givenName = data.idTokenParsed.given_name
    state.user.surname = data.idTokenParsed.family_name
    state.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.cr_id = data.idTokenParsed.cr_id
    state.user.cr_membernumber = data.idTokenParsed.cr_membernumber
}

/**
 * Should be called, when the keycloak token was updated.
 *
 * Currently there is no real difference to ON_VALID_TOKEN_EXISTS.
 * Its only there, if it is needed to differentiate between token updates and the initial login.
 *
 * @param {UserState} state
 * @param {Object} data
 * @param {?string} data.token
 * @param {Object} data.idTokenParsed
 * @param {?string} data.idTokenParsed.email
 * @param {string} data.idTokenParsed.given_name
 * @param {string} data.idTokenParsed.family_name
 * @param {Array<string>} data.idTokenParsed.cr_userrole
 * @param {?string} data.idTokenParsed.cr_membernumber
 */

export function ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS (state, data) {
    state.token = data.token
    state.user.email = data.idTokenParsed.email
    state.user.givenName = data.idTokenParsed.given_name
    state.user.surname = data.idTokenParsed.family_name
    state.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.cr_membernumber = data.idTokenParsed.cr_membernumber
}
