<template>
    <div class="row lierue-info">
        <div class="col-12 box-spacing">
            <h2 v-html="$t('Experiences.Title')"></h2>
            <p v-html="$t('Experiences.Paragraph.1')"></p>
            <p><router-link :to="{ name: 'lierue-uebersicht'}" v-html="$t('Experiences.Paragraph.2.Link')"></router-link>.</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "experiences"

}
</script>

<style scoped lang="less">
.lierue-info {
    .h2 {
        overflow: hidden;
    }

}
</style>
