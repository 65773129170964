<template>
    <div
        v-if="isLoading"
        class="container"
    >
        <crefo-ring-loading additionalClasses="mt"/>
    </div>
    <div v-else>
        <div class="bg-brand box-spacing" ref="lierue-uebersicht-start-position">
            <div class="container">
                <div class="row mb">
                    <div class="col-12">
                        <h1 v-html="$t('LierueUebersicht.Title')"></h1>
                    </div>
                </div>
                <div class="row d-md-none" v-show="!supplierInquiriesConfig.showSupplierInquiries">
                    <div class="col-12">
                        <p>
                            <a href="#" class="link-icon-prev"
                               @click.prevent="supplierInquiriesConfig.showSupplierInquiries = true"><span>Zurück zur Übersicht</span></a>
                        </p>
                    </div>
                </div>
                <div class="row" v-if="!showError">
                    <div class="col-12">
                        <pagination :total-count="supplierinquiries.totalCount" :supplierInquiriesConfig="supplierInquiriesConfig"></pagination>
                    </div>
                </div>
                <div class="row" v-if="showError">
                    <div class="col-12">
                        <crefo-error-message
                            :ajax-error="currentAjaxError"
                            data-qa="lierue-activation-error"
                        >
                        </crefo-error-message>
                    </div>
                </div>
                <div class="row lierue-form-wrapper" v-else>
                    <div class="col-12 bg-white box-shadow-xy">
                        <div class="row d-none d-md-flex">
                            <div class="col-md-6 lierue-steps">
                                <supplier-inquiries
                                    :supplierInquiriesConfig="supplierInquiriesConfig"></supplier-inquiries>
                            </div>
                            <div class="col-md-6">
                                <supplier-inquiry :supplierInquiriesConfig="supplierInquiriesConfig"></supplier-inquiry>
                            </div>
                        </div>
                        <div class="row d-md-none">
                            <div class="col-12 lierue-steps" v-show="supplierInquiriesConfig.showSupplierInquiries">
                                <supplier-inquiries
                                    :supplierInquiriesConfig="supplierInquiriesConfig"></supplier-inquiries>
                            </div>
                            <div class="col-12" v-show="!supplierInquiriesConfig.showSupplierInquiries">
                                <supplier-inquiry :supplierInquiriesConfig="supplierInquiriesConfig"></supplier-inquiry>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!showError">
                    <div class="col-12 pt">
                        <pagination :total-count="supplierinquiries.totalCount" :supplierInquiriesConfig="supplierInquiriesConfig" showPageSize="true"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

/**
 *
 *  A companies participant lists and edits his supplierinquiries
 *
 */

import {mapState} from 'vuex'
import CrefoRingLoading from '@dm/crefo-ring-loading'
import CrefoErrorMessage from '@dm/crefo-error-message'
import {ValidationProvider} from 'vee-validate'
import Pagination from '@/components/uebersicht/pagination'
import SupplierInquiries from '@/components/uebersicht/supplierinquiries'
import SupplierInquiry from '@/components/uebersicht/supplierinquiriy'
import initializr from "@/mixins/initializr";
import scroller from "@/mixins/scroller";

import Vue from "vue";

export default {
    name: 'LierueUebersichtView',
    components: {
        CrefoRingLoading, CrefoErrorMessage,
        ValidationProvider,
        Pagination,
        SupplierInquiries,
        SupplierInquiry
    },
    data() {
        return {
            supplierInquiriesConfig: {
                activeStep: 0,
                showSupplierInquiries: true,
                currentPageStartIndex: 0,
                currentPageSize: 10,
            },
            eventBus: new Vue()
        }
    },
    methods: {
        updateSupplierInquiriesConfig(step) {
            this.supplierInquiriesConfig.activeStep = step
        },
        async supplierInquiryFor(id) {
            const payload = {
                id
            }
            await this.$store.dispatch('supplierinquiryModule/supplierinquiry', payload)
            this.initSupplierinquiryReply(this.$store.commit, this.supplierinquiry.id, this.supplierinquiry.memberUserId)
        },
        async supplierInquiries() {
            const payload = {
                pageSize: this.supplierInquiriesConfig.currentPageSize,
                pageStartIndex: this.supplierInquiriesConfig.currentPageStartIndex,
                status: "OPEN"
            }
            await this.$store.dispatch('supplierinquiryModule/supplierinquiries', payload)
        },
    },
    computed: {
        ...mapState('supplierinquiryModule', ['supplierinquiries', 'supplierinquiry', 'supplierinquiriesFetching', 'ajaxError']),
        isLoading: function () {
            return this.supplierinquiriesFetching === true
        },
        showError() {
            return this.ajaxError !== null
        },
        currentAjaxError() {
            return this.ajaxError
        }
    },
    mixins:[initializr,scroller],
    inject: {
        editEventBus: "editEventBus"

    },
    async created() {
        this.editEventBus.$on("scroll-to-lierue-uebersicht-start", () => {
            this.scrollToStart(this.$refs["lierue-uebersicht-start-position"])
        })
        this.editEventBus.$on("select-supplierinquiries", async () => {
            await this.supplierInquiries()
            this.editEventBus.$emit("select-supplierinquiry", 0)
        })
        this.editEventBus.$on("select-supplierinquiry", (step) => {
            this.updateSupplierInquiriesConfig(step)
            this.editEventBus.$emit("scroll-to-lierue-uebersicht-start")
            if (this.supplierinquiries && this.supplierinquiries.elements) {
                if (step < this.supplierinquiries.elements.length) {
                    this.supplierInquiryFor(this.supplierinquiries.elements[step].id)
                }
            }
        })
        this.editEventBus.$emit("select-supplierinquiries")
    }
}
</script>

<style scoped lang="less">

.lierue-form-wrapper {
    margin-left: 0;
    margin-right: 0;

    .lierue-steps {
        border-right: 1px solid #edece5;
        padding-left: 0;
        padding-right: 0;
        overflow-y: auto;

        /deep/ ul {
            list-style-position: inside;
            list-style-type: none;
        }

        @media only screen and (min-width: 768px) {
            /deep/ ul {
                height: 0;
                margin-bottom: 0;
            }
        }
    }
}

</style>
