export default {

    methods: {
        scrollToStart(element) {
            const headers = document.getElementsByClassName("header-nav");
            let headerHeight = 0
            if (headers && headers.length) {
                headerHeight = headers[0].clientHeight
            }
            if (element) {
                let top = element.offsetTop - headerHeight
                window.scrollTo({top, left: 0, behavior: 'smooth'})
            }
        },
    }
}
