<template>
    <div class="crefo-input">
        <ValidationProvider :name=field :rules=rules :vid=field
                            v-slot="{ invalid, errors, dirty, changed }">

            <div v-if="isCheckbox" class="input-group" :class="{'has-error':((!checkDirty||dirty)&&invalid)}">
                <label v-if="label" :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ label }}
                </label>
                <input type="checkbox"
                       :checked="model"
                       @click=handleChange
                       :data-qa="dataQaInput"
                       data-no-crefo-ui="true">
                <label>{{ label }}</label>
            </div>
            <div v-else class="input-group" :class="{'has-error':((!checkDirty||dirty)&&invalid)}">
                <label v-if="label" :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ label }}
                </label>
                <div class="input-wrapper">
                    <span v-if="tooltip" class="crefo-ui-tooltip">
                        <span class="inline-wrapper">
                            <i class="crefo-ui-icon icon-circle-info" aria-hidden="true"></i>
                            <span class="crefo-tooltip">
                                {{ tooltip }}
                            </span>
                        </span>
                    </span>
                    <input v-if=lazy
                           :type="type"
                           :placeholder="placeholder"
                           :readonly="readonly"
                           v-model.lazy="model"
                           :data-qa="dataQaInput"
                           data-no-crefo-ui="true">
                    <input v-else
                           :type="type"
                           :placeholder="placeholder"
                           :readonly="readonly"
                           v-model="model"
                           :data-qa="dataQaInput"
                           data-no-crefo-ui="true">
                    <em v-if="unit">{{ unit }}</em>
                </div>
                <span v-if="!isCheckbox" class="error-msg" :data-qa="dataQaErrorMsg">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>

import '@/helper/standard-rules'
import {ValidationProvider} from 'vee-validate'

export default {
    components: {ValidationProvider},
    name: "InputField",
    props: {
        field: {
            type: String,
            required: true
        },
        rules: {
            type: [Object, String]
        },
        label: {
            type: String
        },
        tooltip: {
            type: String
        },
        unit: {
            type: String
        },
        type: {
            type: String,
            required: true
        },
        placeholder: {
            type: String
        },
        lazy: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        checkDirty: {
            type: Boolean,
            default: true
        },
        // muss hinzugefügt werden, und wird mit v-model übergeben
        value: {
            type: null
        },
        checked: {
            type: null
        }
    },
    computed: {
        dataQaInput() {
            return this.field
        },
        dataQaErrorMsg() {
            return this.field + "-message"
        },
        model: {
            get() {
/*
                if (this.type === 'checkbox' || this.type === 'radio') {
                    return this.checked;
                    //return this.value;
                }else{
                    return this.value;
                }
*/
                return this.value;
            },
            set(newVal) {
                this.$emit('input', newVal);
            },
        },
        isCheckbox() {
/*
            if (this.type === 'checkbox') {
                return true
            } else {
                return false
            }
*/
            return false
        },

    }
}
</script>
