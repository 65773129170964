/**
 * @type {UserState}
 */
export default {
    authenticated: false,
    token: null,
    dateLocale: 'de',
    ajaxError: null,
    user: {
        cr_id: null,
        givenName: '',
        surname: '',
        email: null,
        user_roles: [],
        cr_membernumber: null,
    }
}

/**
 * @typedef UserState
 * @type {Object}
 * @property {boolean} authenticated - Whether user is authenticated
 * @property {?string} token - The complete keycloak token
 * @property {string} dateLocale - The current dayjs locale for date formatting
 * @property {?AjaxError} ajaxError - Error information
 * @property {Object} user - User data for the current user
 * @property {?string} user.cr_id - cr_id from Token
 * @property {string} user.givenName - Given Name fetched from Token
 * @property {string} user.surname - Surname fetched from Token
 * @property {?string} user.email - Email fetched from Token
 * @property {Array<string>} user.user_roles - User roles fetched from Token
 * @property {?string} user.cr_membernumber - cr_membernumber fetched from Token
 * @see https://github.com/iamkun/dayjs/tree/dev/src/locale
 */
