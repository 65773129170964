/**
 * Should be called, when supplierinquiries is called.
 *
 * @param {UserState} state
 * @param {Object} data
 *
 */

export function SUPPLIERINQUIRIES_FETCHING (state) {
    state.supplierinquiriesFetching = true
}

/**
 * Should be called, when supplierinquiries returns success.
 *
 * @param {UserState} state
 * @param {Object} response
 */

export function SUPPLIERINQUIRIES_SUCCESS (state, response) {
    state.supplierinquiriesFetching = false
    state.supplierinquiries = response.data
    state.ajaxError = null
}


/**
 * Should be called, when supplierinquiries returns an error response.
 *
 * @param {UserState} state
 * @param {Object} error
 */

export function SUPPLIERINQUIRIES_FAILED (state, error) {
    state.supplierinquiriesFetching = false
    state.ajaxError = error
}


/**
 * Should be called, when get supplierinquiry is called.
 *
 * @param {UserState} state
 *
 */

export function SUPPLIERINQUIRY_FETCHING (state) {
    state.supplierinquiryFetching = true
}

/**
 * Should be called, when supplierinquiry returns success.
 *
 * @param {UserState} state
 * @param {Object} response
 */

export function SUPPLIERINQUIRY_SUCCESS (state, response) {
    state.supplierinquiryFetching = false
    state.supplierinquiryDeleting = false
    if (response) {
        state.supplierinquiry = response.data
    }
    state.ajaxError = null
}

/**
 * Should be called, when supplierinquiry returns an error response.
 *
 * @param {UserState} state
 * @param {Object} error
 */

export function SUPPLIERINQUIRY_FAILED (state, error) {
    state.supplierinquiryFetching = false
    state.supplierinquiryDeleting = false
    state.ajaxError = error
}

/**
 * Should be called, when delete supplierinquiry is called.
 *
 * @param {UserState} state
 *
 */

export function SUPPLIERINQUIRY_DELETING (state) {
    state.supplierinquiryDeleting = true
}

/**
 * Should be called, when supplierinquiryReply is called.
 *
 * @param {UserState} state
 *
 */

export function SUPPLIERINQUIRY_REPLY_FETCHING (state) {
    state.supplierinquiryReplyFetching = true
}

/**
 * Should be called, when supplierinquiryReply returns success.
 *
 * @param {UserState} state
 */

export function SUPPLIERINQUIRY_REPLY_SUCCESS (state) {
    state.supplierinquiryReplyFetching = false
    state.ajaxError = null
}


/**
 * Should be called, when supplierinquiryReply returns an error response.
 *
 * @param {UserState} state
 * @param {Object} error
 */

export function SUPPLIERINQUIRY_REPLY_FAILED (state, error) {
    state.supplierinquiryReplyFetching = false
    state.ajaxError = error
}


/**
 * Should be called to set the current supplierInquiryId
 *
 * @param {UserState} state
 * @param {supplierInquiryId} data
 *
 */
export function ON_CHANGE_SUPPLIER_INQUIRY_ID (state, data) {
    state.supplierinquiryReply.supplierInquiryId = data
}

/**
 * Should be called to set the current memberUserId
 *
 * @param {UserState} state
 * @param {memberUserId} data
 *
 */
export function ON_CHANGE_MEMBER_USER_ID (state, data) {
    state.supplierinquiryReply.memberUserId = data
}

/**
 * Should be called to set the current businessRelationExists
 *
 * @param {UserState} state
 * @param {businessRelationExists} data
 *
 */
export function ON_CHANGE_BUSINESSRELATION_EXISTS (state, data) {
    state.supplierinquiryReply.businessRelationExists = data
}

/**
 * Should be called to set the current businessRelationDurationInYears
 *
 * @param {UserState} state
 * @param {businessRelationDurationInYears} data
 *
 */
export function ON_CHANGE_BUSINESS_RELATION_DURATION_IN_YEARS (state, data) {
    state.supplierinquiryReply.businessRelationDurationInYears = data
}

/**
 * Should be called to set the current turnoverYear
 *
 * @param {UserState} state
 * @param {turnoverYear} data
 *
 */
export function ON_CHANGE_TURNOVER_YEAR (state, data) {
    state.supplierinquiryReply.turnoverYear = data
}

/**
 * Should be called to set the current turnoverAmount
 *
 * @param {UserState} state
 * @param {turnoverAmount} data
 *
 */
export function ON_CHANGE_TURNOVER_AMOUNT (state, data) {
    state.supplierinquiryReply.turnoverAmount = data
}

/**
 * Should be called to set the current paymentMode
 *
 * @param {UserState} state
 * @param {paymentMode} data
 *
 */
export function ON_CHANGE_PAYMENT_MODE (state, data) {
    state.supplierinquiryReply.paymentMode = data
}

/**
 * Should be called to set the current targetAgreementInDays
 *
 * @param {UserState} state
 * @param {targetAgreementInDays} data
 *
 */
export function ON_CHANGE_TARGET_AGREEMENT_IN_DAYS (state, data) {
    state.supplierinquiryReply.targetAgreementInDays = data
}

/**
 * Should be called to set the current delayedPaymentInDays
 *
 * @param {UserState} state
 * @param {delayedPaymentInDays} data
 *
 */
export function ON_CHANGE_DELAYED_PAYMENT_IN_DAYS (state, data) {
    state.supplierinquiryReply.delayedPaymentInDays = data
}

/**
 * Should be called to set the current dunningCount
 *
 * @param {UserState} state
 * @param {dunningCount} data
 *
 */
export function ON_CHANGE_DUNNING_COUNT (state, data) {
    state.supplierinquiryReply.dunningCount = data
}

/**
 * Should be called to set the current creditLimit
 *
 * @param {UserState} state
 * @param {creditLimit} data
 *
 */
export function ON_CHANGE_CREDIT_LIMIT (state, data) {
    state.supplierinquiryReply.creditLimit = data
}

/**
 * Should be called to set the current remarks
 *
 * @param {UserState} state
 * @param {remarks} data
 *
 */
export function ON_CHANGE_REMARKS (state, data) {
    state.supplierinquiryReply.remarks = data
}

