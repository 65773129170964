import Vue from 'vue'
import Vuex from 'vuex'

import userModule from './module-user'
import supplierinquiryModule from './module-supplierinquiry'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        userModule,
        supplierinquiryModule
    }
})
