import * as api from '@/services/index.js'
import { getParsedError } from '@/helper/axios-error'
import HelperWebtrekk from "@dm/helper-webtrekk";
import i18n from '@/i18n'


export function supplierinquiries ({ state, commit }, data) {
    commit('SUPPLIERINQUIRIES_FETCHING')
    return api.getSupplierinquiries(data).then((response) => {
        commit('SUPPLIERINQUIRIES_SUCCESS', response)
    }).catch((err) => {
        commit('SUPPLIERINQUIRIES_FAILED', getParsedError(err, errorMessage(err)))
    })
}

export function supplierinquiry ({ state, commit }, data) {
    commit('SUPPLIERINQUIRY_FETCHING')
    return api.getSupplierinquiry(data).then((response) => {
        commit('SUPPLIERINQUIRY_SUCCESS', response)
    }).catch((err) => {
        commit('SUPPLIERINQUIRY_FAILED', getParsedError(err, errorMessage(err)))
    })
}

export function deleteSupplierinquiry ({ state, commit }, data) {
    commit('SUPPLIERINQUIRY_DELETING')
    return api.deleteSupplierinquiry(data).then(() => {
        commit('SUPPLIERINQUIRY_SUCCESS')
        trackActionAction('lierue_action_deleteSupplierinquiry')
    }).catch((err) => {
        commit('SUPPLIERINQUIRY_FAILED', getParsedError(err, errorMessage(err)))
        trackActionAction('lierue_action_deleteSupplierinquiry', err)
    })
}


export function supplierinquiryReply ({ state, commit }, data) {
    commit('SUPPLIERINQUIRY_REPLY_FETCHING')
    return api.postSupplierinquiryReply(data).then(() => {
        commit('SUPPLIERINQUIRY_REPLY_SUCCESS')
        trackActionAction('lierue_action_supplierinquiryReply')
    }).catch((err) => {
        commit('SUPPLIERINQUIRY_REPLY_FAILED', getParsedError(err, errorMessage(err)))
        trackActionAction('lierue_action_supplierinquiryReply', err)
    })
}

const errorMessage = error => {
    let key = null;
    if (error && error.response) {
        if (error.response.status === 401) {
            key = 'Error.401'
        } else if (error.response.status === 403) {
            key = 'Error.403'
        } else if (error.response.status === 500) {
            key = 'Error.500'
        } else if (error.response.status === 302) {
            key = 'Error.302'
        }
    } else {
        key = 'Error.BACKENDDEV'
    }
    if (key) {
        return i18n.t(key)
    } else {
        return null
    }
}

/**
 * @param {string} name
 * @param {Object} err
 */
function trackActionAction (name, err) {
    let properties = null
    if (err) {
        properties = {
            8: getDescriptionFromNameAndData(name),
            3: getParsedError(err, "", "WebtrekkAktivierung").defaultErrorMessage,
            5: "ERROR",
            6: "LIERUE",
        }
    } else {
        properties = {
            8: getDescriptionFromNameAndData(name),
            5: "OK",
            6: "LIERUE"
        }
    }
    HelperWebtrekk.trackAction(name, properties)
}

/**
 * @param {string} name
 * @param {Object} data
 *
 */
function getDescriptionFromNameAndData (name) {
    let result = ''
    if (name === 'lierue_action_supplierinquiryReply') {
        result = 'Lieferantenrückfrage senden'
    } else if (name === 'lierue_action_deleteSupplierinquiry') {
        result = 'Lieferantenrückfrage löschen'
    }
    return result
}
