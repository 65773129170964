export default {
    computed: {
        /**
         * @return {boolean}
         */
        showComponent () {
            return this.$i18n.locale === "de_DE"
        },
        /**
         * @return {string}
         */
        amountPlaceholder () {
            let result
            if (this.$i18n.locale === "en_US") {
                result = "0.00"
            } else {
                result = "0,00"
            }
            return result
        }
    },
    methods: {
        /**
         * @callback cb
         * @param {string} type
         * @param {?any} payload
         */
        /**
         * @param {cb} commit
         * @param {string}  inquiryId
         * @param {string}  memberUserId
         */
        initSupplierinquiryReply: (commit, inquiryId, memberUserId) => {
            commit('supplierinquiryModule/ON_CHANGE_SUPPLIER_INQUIRY_ID', inquiryId)
            commit('supplierinquiryModule/ON_CHANGE_MEMBER_USER_ID', memberUserId)
            commit('supplierinquiryModule/ON_CHANGE_BUSINESSRELATION_EXISTS', null)
            commit('supplierinquiryModule/ON_CHANGE_BUSINESS_RELATION_DURATION_IN_YEARS', null)
            commit('supplierinquiryModule/ON_CHANGE_TURNOVER_AMOUNT', null)
            commit('supplierinquiryModule/ON_CHANGE_TURNOVER_YEAR', new Date().getFullYear())
            commit('supplierinquiryModule/ON_CHANGE_PAYMENT_MODE', null)
            commit('supplierinquiryModule/ON_CHANGE_CREDIT_LIMIT', null)
            commit('supplierinquiryModule/ON_CHANGE_REMARKS', "")
        }
    }
}
