<template>
    <div class="bg-brand bg-brand-light-grey box-spacing bg-brand--lierue" v-if="showComponent">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4 mb">
                    <div class="box-shadow-xy box-spacing bg-white">
                        <h3>Was genau ist das?</h3>
                        <p>Was genau sind eigentlich Lieferanten&shy;rückfragen? Und wie beantworte ich eine solche
                            Rückfrage? Diese und mehr Informationen finden Sie in unserem Hilfe-Bereich für
                            <a href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/">Lieferanten&shy;rückfragen</a>.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb">
                    <div class="box-shadow-xy box-spacing bg-white">
                        <h3>Was ist der Vorteil?</h3>
                        <p>Ihr Feedback ist wichtig!
                            Ihre beantworteten Lieferanten&shy;rückfragen fließen in unsere Analyse mit ein und
                            ermöglichen
                            eine noch bessere Bewertung. Werden Sie <a href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/">Qualitätsverbesserer</a>!</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb">
                    <div class="box-shadow-xy box-spacing bg-white">
                        <h3>Meine Crefo</h3>
                        <p>Präsentieren Sie sich selbst und Ihr Unternehmen im besten Licht. Nutzen Sie die
                            Selbstauskunft und teilen Sie uns <a href="/ueber-creditreform/meine-crefo/">Informationen und Fakten zu Ihrem
                                Unternehmen</a> mit. Diese
                            fließen in Ihre Unternehmensbewertung ein.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

/**
 *
 *  Show information about sucess factors
 *
 */
import initializr from "@/mixins/initializr";

export default {
    name: 'Successfactors',
    data: () => {
        return {
            mehrSicherheitLink: null,
            unsereQuellenLink: null,
            mehrInfosLink: null,
            ihreAngabenLink: null
        }
    },
    mixins:[initializr],
}
</script>

<style scoped lang="less">

.bg-brand--lierue {

    .box-shadow-xy {
        height: 400px;

        img {
            width: 100%;
        }
    }
}


@media only screen and (max-width: 991px) {
    .bg-brand--lierue {
        .box-shadow-xy {
            height: auto;
        }
    }
}

</style>
