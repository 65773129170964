<template>
    <div class="pagination-wrapper lierue-pagination-wrapper"
         :class="{'lierue-pagination-wrapper--show':supplierInquiriesConfig.showSupplierInquiries===true}"
    >
        <div class="lierue-crefo-input-label"><span v-show="showPageSize">{{$t("Pagination.Label.AnzahlProSeite")}}</span></div>
        <div class="crefo-input lierue-crefo-input crefo-ui-select" v-if="showPageSize">
            <div class="input-group" data-qa="pager-selectPageSize">
                <div class="select-group">
                    <div class="input-wrapper">
                        <select v-model="supplierInquiriesConfig.currentPageSize" @change="firstSupplierInquiries" data-qa="comboBox-container" >
                            <option value="10" data-qa="comboBox-list-item">10</option>
                            <option value="25" data-qa="comboBox-list-item">25</option>
                            <option value="50" data-qa="comboBox-list-item">50</option>
                            <option value="100" data-qa="comboBox-list-item">100</option>
                            <option value="200" data-qa="comboBox-list-item">200</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <ul class="pagination">
            <li><span>{{ this.pageInfo }}</span></li>
            <li class="first" :class="{'disabled':previousDisabled}">
                <a href="#" @click="previousSupplierInquiries">
                    <i class="crefo-ui-icon icon-chevron-prev" aria-hidden="true"></i>
                </a>
            </li>
            <li class="last" :class="{'disabled':nextDisabled}">
                <a href="#" @click="nextSupplierInquiries">
                    <i class="crefo-ui-icon icon-chevron-next" aria-hidden="true"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

import i18n from "@/i18n";

export default {
    name: "pagination",
    props: {
        totalCount: null,
        showPageSize: false,
        supplierInquiriesConfig: null,
    },
    methods: {
        nextSupplierInquiries() {
            if (this.supplierInquiriesConfig.currentPageStartIndex < this.totalCount / this.supplierInquiriesConfig.currentPageSize - 1) {
                this.supplierInquiriesConfig.currentPageStartIndex += 1
                this.editEventBus.$emit("select-supplierinquiries")
            }
        },
        previousSupplierInquiries() {
            if (this.supplierInquiriesConfig.currentPageStartIndex > 0) {
                this.supplierInquiriesConfig.currentPageStartIndex -= 1
                this.editEventBus.$emit("select-supplierinquiries")
            }
        },
        firstSupplierInquiries() {
            this.supplierInquiriesConfig.currentPageStartIndex = 0
            this.editEventBus.$emit("select-supplierinquiries")
        }
    },
    computed: {
        pageInfo() {
            return `${this.supplierInquiriesConfig.currentPageStartIndex * this.supplierInquiriesConfig.currentPageSize + 1} - ${Math.min((this.supplierInquiriesConfig.currentPageStartIndex + 1) * this.supplierInquiriesConfig.currentPageSize, this.totalCount)} ${i18n.t("Pagination.Label.Von")} ${this.totalCount}`
        },
        previousDisabled() {
            return this.supplierInquiriesConfig.currentPageStartIndex === 0
        },
        nextDisabled() {
            return this.supplierInquiriesConfig.currentPageStartIndex >= this.totalCount / this.supplierInquiriesConfig.currentPageSize - 1
        }
    },
    inject: {
        editEventBus: "editEventBus",
    }

}
</script>

<style scoped lang="less">
/deep/ .select-items div, /deep/ .select-selected {
    text-align: left;
    min-width: calc(2.4rem + 24px + 3 * 15px);
    padding: 0.625rem calc(0.6rem + 0.6rem + 0.6rem + 24px) 0.625rem 1.2rem;
}

lierue-pagination-wrapper /deep/ .crefo-input .input-group .select-group .input-wrapper.show-options:after {
    transform: translateY(-50%) rotate(180deg);
    color: #009ee2;
}

@media only screen and (max-width: 767px) {
    .lierue-pagination-wrapper {
        .crefo-input {
            margin-left: auto;
            margin-right: 0px;
        }
    }
}

lierue-pagination-wrapper /deep/ .select-items div, /deep/ .select-selected {
    min-width: calc(2.4rem + 24px + 3 * 15px);
    padding: 0.625rem calc(0.6rem + 0.6rem + 0.6rem + 24px) 0.625rem 1.2rem;
}

lierue-pagination-wrapper /deep/ .crefo-input .input-group .select-group .input-wrapper.show-options:after {
    transform: translateY(-50%) rotate(180deg);
    color: #009ee2;
}


.lierue-pagination-wrapper {
    display: flex;
    color: #7f7f7f;

    div.lierue-crefo-input-label {
        margin-right: 15px;
        padding: 0.625rem 0;

        /deep/ span {
            display: block;
            padding: 0 5px;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
        }
    }

    ul.pagination {
        margin-left: auto;

        li {
            span {
                margin-right: 15px;
                font-size: 15px;
                font-weight: 400;
                color: #7f7f7f;

            }
        }

        li.first, li.last {
            a {
                i {
                    &:hover {
                        background-color: #009ee2;
                        color: #ffff;
                        border-radius: 9999px;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .lierue-pagination-wrapper {
        display: none;
        color: #7f7f7f;


        div.lierue-crefo-input-label {
            margin: unset;
            padding: 0.625rem 0;

            span {
                display: block;
                padding: 0 5px;
                min-width: 28px;
                height: 28px;
                line-height: 28px;
            }
        }

        ul.pagination {
            flex: 100%;
            text-align: right;

            li {
                span {
                    margin-right: 15px;
                    font-size: 15px;
                    font-weight: 400;
                }
            }

            li.first, li.last {
                a {
                    i {
                        &:hover {
                            background-color: #009ee2;
                            color: #ffff;
                            border-radius: 9999px;
                        }
                    }
                }
            }
        }
    }

    .lierue-pagination-wrapper--show {
        display: flex;
        flex-wrap: wrap;
    }

}

</style>
