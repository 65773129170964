import axios from 'axios'

// Set default headers to prevent caching
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}

/**
 * Before any request, we will check the lifetime of the Keycloak token.
 * If it's already expired or expiring soon (within the next 10 seconds),
 * we will force a token update before sending the request.
 */
axios.interceptors.request.use(
    async (config) => {
        if (window.keycloak) {
            let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
            if (tokenExpires < 10) {
                console.log('[axios] Token expires soon. Updating token before sending request')
                await window.updateKeycloakToken()
            } else {
                console.log('[axios] Token still valid. No need to refresh before sending request')
            }
            config.headers.Authorization = `Bearer ${ window.keycloak.token }`
        }

        if (sessionStorage.getItem('actForMemberId') !== null) {
            config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
        } else {
            delete config.headers['X-Act-For-Member-Id']
        }

        return config
    },
    (error) => Promise.reject(error)
)

/**
 * Sends a GET request to receive supplierinquiries
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getSupplierinquiries = (data) =>
    axios.get(`/cbra/supplierinquiries?pageSize=${ data.pageSize }&pageStartIndex=${ data.pageStartIndex }&status=${ data.status }`)

/**
 * Sends a GET request to receive a supplierinquiry
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getSupplierinquiry = (data) =>
    axios.get(`/cbra/supplierinquiry/${ data.id }`)

/**
 * Sends a DELETE request to remove a supplierinquiry
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const deleteSupplierinquiry = (data) =>
    axios.delete(`/cbra/supplierinquiry/${ data.supplierInquiryId }`)

/**
 * Sends a POST request to send a supplierinquiryReply
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const postSupplierinquiryReply = (data) => {
    let payload = data
    return axios.post(`/cbra/supplierinquiry/${ data.supplierInquiryId }/reply`, payload)
}


/**
 * Sends a GET request to receive personal user data
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCbraUser = () =>
    axios.get(`/cbra/user`)

/**
 * Sends a GET request to receive personal user data
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getTestTransactions = () =>
    axios.get('/cbra/transactions?pageSize=3&pageStartIndex=0&sort=OBSERVATION_END_TIME_DESC&transactionType=REPORT&favourites=false&observationType=ALL_OBSERVATION')
