export default {

    methods: {

        /**
         * @param {string} obj
         * @return {string}
         */
        formatString: (obj) => {
            let result = null
            if (obj) {
                result = String(obj)
            }
            return result
        },

        /**
         * @param {string} obj
         * @return {string}
         */
        parseString: (obj) => {
            let result = null
            if (obj) {
                result = String(obj)
            }
            return result
        },

        /**
         * @param {number} obj
         * @return {string}
         */
        formatNumber: (obj) => {
            let result = null
            if (obj) {
                result = String(obj)
            }
            return result
        },

        /**
         * @param {string} obj
         * @return {number}
         */
        parseNumber: (obj) => {
            let result = null
            let formattedObj = String(obj).replace(/[^0-9]/g, '')
            if (formattedObj) {
                result = Number(formattedObj)
            }
            return result
        },


        /**
         * @param {string} obj
         * @return {Boolean}
         */
        parseBoolean: (obj) => {
            let result = null
            if (obj) {
                if ("true" === obj) {
                    result = true
                }
                if ("false" === obj) {
                    result = false
                }
            }
            return result
        },


        /**
         * @param {number} obj
         * @param {?string} locale
         * @return {string}
         */
        formatAmount: (obj, locale) => {
            let result = ""
            if (obj) {
                result = Intl.NumberFormat(locale?locale.replace('_','-'):locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(obj)
                if (result === "NaN") {
                    result = ""
                }
            }
            return result
        },

        /**
         * @param {string} obj
         * @param {?string} locale
         * @return {number}
         */
        parseAmount: (obj, locale) => {
            let result = null
            let formattedObj = null
            if (locale && locale.startsWith('en')) {
                formattedObj = String(obj).replace(/[^0-9.]/g, '')
            } else {
                formattedObj = String(obj).replace(/[^0-9,]/g, '').replace(',', '.')
            }
            if (formattedObj) {
                result = Number(formattedObj)
            }
            return result
        },

        /**
         * @param {Object} obj
         * @return {string}
         */
        formatDate: (obj) => {
            let result = null
            const date = new Date(obj)
            if (date && date.getDate() && date.getMonth() && date.getFullYear()) {
                result = `${ ('0' + date.getDate()).slice(-2) }.${ ('0' + (date.getMonth() + 1)).slice(-2) }.${ (date.getFullYear()) }`
            }
            return result

        },

        /**
         * @param {string} obj
         * @return {Object}
         */
        parseDate: (obj) => {
            let dateFormat = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/
            let result = null
            if (obj && typeof obj === 'string' && obj.match(dateFormat)) {
                result = {
                    jahr: Number(obj.slice(6, 10)),
                    monat: Number(obj.slice(3, 5)),
                    tag: Number(obj.slice(0, 2))
                }
            }
            return result
        }
    }
}
