/**
 * @type {SupplierInquiry}
 */
export default {
    supplierinquiriesFetching: null,
    supplierinquiryFetching: null,
    supplierinquiryDeleting: null,
    supplierinquiryReplyFetching: null,
    ajaxError: null,
    supplierinquiries: {
        totalCount: null,
        elements: []
    },
    supplierinquiry: {
        id: null,
        creationTime: null,
        memberUserId: null,
        memberId: null,
        productOrderId: null,
        transactionId: null,
        referenceNumber: null,
        status: null,
        completionTime: null,
        businessId: null,
        businessName: null,
        businessAddressLine: null
    },
    supplierinquiryReply: {
        supplierInquiryId: null,
        memberUserId: null,
        businessRelationExists: null,
        businessRelationDurationInYears: null,
        turnoverYear: null,
        turnoverAmount: null,
        paymentMode: null,
        targetAgreementInDays: null,
        delayedPaymentInDays: null,
        dunningCount: null,
        creditLimit: null,
        remarks: null
    }
}

/**
 * @typedef SupplierInquiry
 * @type {Object}
 * @property {?boolean} supplierinquiriesFetching - While supplierinquiries is being fetched, null means never fetched till now
 * @property {?AjaxError} ajaxError - Error information
 *
 * @typedef SupplierInquiries
 * @type {Object}
 *
 */
