<template>
    <div
        v-if="isLoading"
        class="container"
    >
        <crefo-ring-loading additionalClasses="mt"/>
    </div>
    <div v-else>
        <div class="bg-brand box-spacing" ref="lierue-start-position">
            <div class="container">
                <div class="row" v-if="showError">
                    <div class="col-12">
                        <crefo-error-message
                            :ajax-error="currentAjaxError"
                            data-qa="lierue-activation-error"
                        >
                        </crefo-error-message>
                    </div>
                </div>
                <div class="row lierue-form-wrapper" v-else>
                    <div class="col-12 bg-white box-shadow-xy">
                        <div class="row d-none d-lg-flex">
                            <div class="col-7">
                                <supplier-inquiry></supplier-inquiry>
                            </div>
                            <div class="col-5">
                                <experiences></experiences>
                            </div>
                        </div>
                        <div class="row d-lg-none">
                            <div class="col-12">
                                <experiences></experiences>
                            </div>
                        </div>
                        <div class="row d-lg-none">
                            <div class="col-12">
                                <supplier-inquiry></supplier-inquiry>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <successfactors></successfactors>
    </div>
</template>

<script>

/**
 *
 *  A companies participant answers the supplier inquiry
 *
 */

import {mapState} from 'vuex'
import CrefoRingLoading from '@dm/crefo-ring-loading'
import CrefoErrorMessage from '@dm/crefo-error-message'
import {ValidationProvider} from 'vee-validate'
import Experiences from '@/components/uebersicht/experiences'
import Successfactors from '@/components/commons/successfactors'
import SupplierInquiry from '@/components/uebersicht/supplierinquiriy'
import initializr from '@/mixins/initializr'
import scroller from "@/mixins/scroller";



export default {
    name: 'LierueView',
    components: {
        Successfactors,
        Experiences,
        CrefoRingLoading,
        CrefoErrorMessage,
        ValidationProvider,
        SupplierInquiry
    },
    props: {
        supplierInquiryId: {
            type: String,
            required: true
        },
    },
    methods: {
        async supplierInquiryFor(id) {
            const payload = {
                id
            }
            await this.$store.dispatch('supplierinquiryModule/supplierinquiry', payload)
            this.initSupplierinquiryReply(this.$store.commit, this.supplierinquiry.id, this.supplierinquiry.memberUserId)
        },
    },
    computed: {
        ...mapState('supplierinquiryModule', ['supplierinquiry', 'supplierinquiryFetching', 'ajaxError']),
        isLoading: function () {
            return this.supplierinquiryFetching === true
        },
        showError() {
            return this.ajaxError !== null
        },
        currentAjaxError() {
            return this.ajaxError
        }
    },
    mixins: [initializr, scroller],
    inject: {
        editEventBus: "editEventBus"
    },
    async created() {
        this.editEventBus.$on("scroll-to-lierue-start", () => {
            this.scrollToStart(this.$refs["lierue-start-position"])
        })
        this.supplierInquiryFor(this.supplierInquiryId)
    }
}
</script>

<style scoped lang="less">

.lierue-form-wrapper {
    margin-left: 0;
    margin-right: 0;

    .lierue-steps {
        border-right: 1px solid #edece5;
        padding-left: 0;
        padding-right: 0;
        overflow-y: auto;

        /deep/ ul {
            list-style-position: inside;
            list-style-type: none;
        }

        @media only screen and (min-width: 768px) {
            /deep/ ul {
                height: 0;
                margin-bottom: 0;
            }
        }
    }
}

</style>
