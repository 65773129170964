<template>
    <div>
        <ul v-if="this.supplierinquiries">
            <li class="lierue-step" :class="activeStep(index)"
                v-for="(element, index) in supplierinquiries.elements" data-qa='in-box-messageListEntry'>
                <div class="lierue-step-sidebar"
                >
                    <div class="crefo-input">
                        <div class="input-group">
                            <div class="checkbox-group">
                                <div class="crefo-checkbox">
                                    <div class="input-wrapper">
                                        <input type="checkbox" :id="'lierue-'+index+'-delete'">
                                        <label :for="'lierue-'+index+'-delete'">
                                            <div class="lierue-step-circle"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lierue-step-main" @click.prevent="selectStep(index)"
                >
                    <div class="lierue-step-address">
                        <p data-qa="businessName">{{ element.businessName }}</p>
                        <p>{{ element.businessAddressLine }}</p>
                    </div>
                    <div class="lierue-step-date">
                        <p><small data-qa="creationTime">{{ formatDate(element.creationTime) }}</small></p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapState} from "vuex";
import formatter from "@/mixins/formatter"

export default {
    name: "SupplierInquiries",
    props: {
        supplierInquiriesConfig: null,
    },
    methods: {
        selectStep(step) {
            this.supplierInquiriesConfig.showSupplierInquiries = false
            this.editEventBus.$emit("select-supplierinquiry", step)
        },
        activeStep(step) {
            let result = null
            if (this.supplierInquiriesConfig.activeStep === step) {
                result = 'lierue-step--active'
            }
            return result
        }
    },
    computed: {
        ...mapState('supplierinquiryModule', ['supplierinquiries']),
    },
    inject: {
        editEventBus: "editEventBus"
    },
    mixins: [formatter]

}
</script>

<style scoped lang="less">

.lierue-step {
    border-bottom: 1px solid #edece5;
    color: #7f7f7f;

    &:hover {
        background-color: rgba(0, 158, 226, 0.15);
    }

    .lierue-step-main {
        display: flex;
        cursor: pointer;

        .lierue-step-address {
            padding: 15px 15px 0 15px;
            font-weight: 700;
            min-height: 97px;

            p {
                word-break: break-word
            }

            p:first-child {
                color: #4c4c4c;
            }
        }

        .lierue-step-date {
            margin-left: auto;
            padding: 15px 15px 0 15px;
        }
    }

    &--active, &--active:hover {
        background-color: #009ee2;

        .lierue-step-main {
            .lierue-step-address, .lierue-step-date {
                p, p:first-child {
                    color: #ffffff;
                }
            }
        }
    }

    .lierue-step-sidebar {
        display: none;
        padding: 15px 15px 0 15px;

        label {
            width: 15px;
            height: 15px;
            padding-left: 30px;

            &::before {
                width: 15px;
                height: 15px;
            }

            &::after {
                font-size: .85rem;
                width: 15px;
                height: 15px;
                top: 0.25rem;
                left: 0.09rem;
            }
        }

        .lierue-step-circle {
            visibility: hidden;
            margin-top: 7px;
            width: 15px;
            height: 15px;
            border-radius: 9999px;
            background-color: #a763a4;
        }
    }
}


</style>
