import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { dispatchEvent } from '@/helper/polyfill'
import LierueUebersichtView from '@/views/lierue-uebersicht'
import LierueView from '@/views/lierue'

Vue.use(VueRouter)

const BASE_PATH_MICROFRONTEND_CONTAINER = '/portal'

const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = 'lieferantenrueckfrage'

const isAuthenticated = (to, from, next, timeout = 100) => {
    if (store.state.userModule.authenticated) {
        next()
    } else if (timeout >= 2000 && (window.keycloak && window.keycloak.token)) {
        next()
    } else if (timeout >= 2000) {
        dispatchEvent('redirectToLogin')
        setTimeout(() => {
            next(false)
        }, 3000)
    } else {
        setTimeout(() => {
            isAuthenticated(to, from, next, timeout * 2)
        }, timeout)
    }
}

const routes = [
    {
        // Startseite mit automatischer Weiterleitung zur 'Übersicht Lieferantenrückfragen'
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}`,
        redirect: { name: 'lierue-uebersicht' }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/lieferantenrueckfrage-uebersicht`,
        name: 'lierue-uebersicht',
        component: LierueUebersichtView,
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, next)
        }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/lieferantenrueckfrage`,
        name: 'lierue',
        component: LierueView,
        props: (route) => ({
            supplierInquiryId: typeof route.query.supplierInquiryId === 'string' ? route.query.supplierInquiryId : '',
        }),

        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, next)
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: '/', // this is crucial in order to rewrite links to the single-spa container!
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (!to.hash) {
            window.scroll(0, 0)
        }
    }
})

export default router
