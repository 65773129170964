import { max_value, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from '@/i18n'

extend('numeric', {
    ...numeric,
    message: (fieldName) => {
        return i18n.t(`StandardRules.Message.Numeric`)
    }
});

extend('max_value', {
        ...max_value,
        message: (fieldName, args) => {
            return i18n.t(`StandardRules.Message.MaxValue`,  {count: args.max})
        }
    }
);
