import i18n from '../i18n'

export default {

    computed: {
        paymentModes: () => {
            const map = new Map()
            map.set("CASH", i18n.t("Supplierinquiry.Key.PaymentMode.Cash"))
            map.set("DIRECT_DEBIT", i18n.t("Supplierinquiry.Key.PaymentMode.DirectDebit"))
            map.set("ADVANCE", i18n.t("Supplierinquiry.Key.PaymentMode.Advance"))
            map.set("DISCOUNT", i18n.t("Supplierinquiry.Key.PaymentMode.Discount"))
            map.set("WITHIN_AGREED_TERMS", i18n.t("Supplierinquiry.Key.PaymentMode.WithinAgreedTerms"))
            map.set("DELAYED_PAYMENT", i18n.t("Supplierinquiry.Key.PaymentMode.DelayedPayment"))
            map.set("AFTER_DUNNING", i18n.t("Supplierinquiry.Key.PaymentMode.AfterDunning"))
            map.set("AFTER_DEFAULT_SUMMONS", i18n.t("Supplierinquiry.Key.PaymentMode.AfterDefaultSummons"))
            return Array.from(map)
        },
    },
}
